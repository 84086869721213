import React, { FC, useState, useContext, useEffect } from 'react'
import { navigate } from 'gatsby'
import { IMaskInput } from 'react-imask'
import { Formik, Form, Field, getIn } from 'formik'
import FormikErrorFocus from 'formik-error-focus'
import { useMsal, useAccount } from '@azure/msal-react'

import { PageTitle } from '@components/configs/PageTitle'
import { logoLavalIcon } from '@images/icons'
import { Icon } from '@components/ui/Icon'
import RequiredIcon from '@components/ui/requiredIcon'
import { joinClasses } from '@utils/styles'
import { ButtonLink } from '@components/ui/ButtonLink'
import MapForm from '@components/ui/map/MapForm'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { formatStrapiText } from '@utils/methods'
import * as pageUtils from '@pages/auth/__index.utils'
import { User, Contact } from '@services/models'
import { AddressInfo } from '@components/ui/map/mapForm.interfaces'
import {
	EMAIL_NOTIFICATION_OPTION,
	LIVING_IN_LAVAL,
	GenderEnum,
	LanguageEnum
} from '@services/constants'
import config from '@utils/config'
import DOMPurify from 'dompurify'
import Text from '@components/ui/text'
import Notification from '@components/ui/notification'
import { setRequestListStore } from '@services/store/requestList'
import { useAppDispatch } from '@services/store'

const MyAccountInformation: FC = () => {
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(true)

	const { accounts } = useMsal() ?? []
	const account = useAccount(accounts[0] || {})

	const authRedirect: string = config.localStorage.authRedirect
	const link: string | null = localStorage.getItem(authRedirect)
	const dispatch = useAppDispatch()

	const { setAuthUser, pageData } = useContext(AppStateContext)

	const addTargetBlankToLink = () => {
		const linksContainer = document.getElementsByClassName('link-redirect')
		Array.prototype.forEach.call(linksContainer, (container: HTMLElement) => {
			const links = container.getElementsByTagName('a')
			Array.prototype.forEach.call(links, (link) => {
				link.setAttribute('target', '_blank')
			})
		})
	}

	useEffect(() => {
		const fetchUser = async () => {
			if (account) {
				try {
					const user = await User.buildAsync(account)
					setAuthUser(user)

					dispatch(
						setRequestListStore({
							fetching: false,
							fetched: true,
							requests: user.requests
						})
					)

					setTimeout(() => {
						if (link) {
							localStorage.removeItem(authRedirect)

							navigate(link)
							return
						}
						navigate(config.homepage.baseURL)
					}, 50)
				} catch (error: any) {
					console.error(error?.message)
				} finally {
					setTimeout(() => setIsLoading(false), 200)
				}
			}
		}

		fetchUser().catch(console.error)
	}, [])

	const formLabel = (
		labelText: string,
		hasError?: boolean,
		errorText?: string,
		required: boolean = true
	) => {
		return (
			<>
				<label
					className={joinClasses([
						pageUtils.classes.formLabel,
						hasError ? pageUtils.classes.textError : ''
					])}
				>
					{labelText}
					{required && <RequiredIcon />}
				</label>
				{hasError && errorText ? (
					<span
						className={joinClasses([
							pageUtils.classes.textError,
							pageUtils.classes.textDescription
						])}
					>
						{pageData.assets[errorText]}
					</span>
				) : null}
			</>
		)
	}

	const handleSubmit = async (contact: Contact) => {
		setIsLoading(true)

		if (!account) {
			// we must have an account, otherwise, we're not logged in and we should not be able to access this page.
			navigate(config.homepage.baseURL)

			throw new Error(
				'No account was found to perform a contact update for the user'
			)
		}

		try {
			setIsSubmitting(true)

			contact.address.prepareProperties()
			contact.applyAccountValues(account)

			await contact.saveAsync()
			const user: User = await User.buildAsync(account, contact)

			setAuthUser(user)
			localStorage.setItem('launchTutorial', 'true')

			if (link) {
				localStorage.removeItem(authRedirect)

				navigate(link)

				return
			}

			navigate(config.homepage.baseURL)
		} catch (error: any) {
			// TODO: request a wireframe for this
			alert(error?.message)
			console.error('Backend Error: ', error?.message)

			throw new Error(error?.message)
		} finally {
			setIsSubmitting(false)
			setTimeout(() => setIsLoading(false), 200)
		}
	}

	if (isLoading) {
		return (
			<div className={pageUtils.classes.container}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<div className={`${pageUtils.classes.loader} spin`} />
					<span style={{ fontSize: '1rem' }}>{pageData?.assets?.loading}</span>
				</div>
			</div>
		)
	}

	return (
		<AppStateContext.Consumer>
			{({ pageData: { title, assets } }) => (
				<>
					<PageTitle title={formatStrapiText(title)} />
					<div className={pageUtils.classes.container}>
						<Icon
							src={logoLavalIcon}
							className={pageUtils.classes.marginAuto}
						/>
						<span className={pageUtils.classes.mainTitle}>
							{formatStrapiText(assets?.label_completeInformation)}
						</span>

						<div className={pageUtils.classes.notification}>
							<Notification
								text={assets?.request_form_completeInformation_notification}
								type="info"
								hasHtml
							/>
						</div>

						<Formik
							initialValues={new Contact()}
							validationSchema={pageUtils.SignupSchema}
							onSubmit={(values, { setSubmitting }) => {
								setSubmitting(true)
								handleSubmit(values)
							}}
						>
							{({ errors, touched, setFieldValue, values }) => {
								return (
									<Form>
										<fieldset className={pageUtils.classes.fieldSet}>
											<Field name="gender" as="select">
												{({ field }) => (
													<div className={pageUtils.classes.selectContainer}>
														<label className={pageUtils.classes.formLabel}>
															{formatStrapiText(assets?.label_greetings)}
															<RequiredIcon />
														</label>
														<select
															className={joinClasses([
																pageUtils.classes.formSelect,
																pageUtils.classes.formInput
															])}
															required
															{...field}
														>
															<option value={GenderEnum.FEMALE}>
																{formatStrapiText(assets?.label_greetWoman)}
															</option>
															<option value={GenderEnum.MALE}>
																{formatStrapiText(assets?.label_greetMan)}
															</option>
														</select>
													</div>
												)}
											</Field>
											<div className={pageUtils.classes.inputBlock}>
												<Field name="firstName">
													{({ field }) => (
														<div
															className={pageUtils.classes.responsiveContainer}
														>
															{formLabel(
																formatStrapiText(assets?.label_firstName),
																!!errors.firstName && touched.firstName,
																errors.firstName
															)}
															<IMaskInput
																mask={pageUtils.MASKS.names}
																autoComplete="given-name"
																minLength={2}
																maxLength={50}
																className={joinClasses([
																	pageUtils.classes.formInput,
																	errors.firstName && touched.firstName
																		? pageUtils.classes.inputError
																		: ''
																])}
																placeholder=""
																{...field}
															/>
														</div>
													)}
												</Field>
												<Field name="lastName">
													{({ field }) => (
														<div
															className={pageUtils.classes.responsiveContainer}
														>
															{formLabel(
																formatStrapiText(assets?.label_lastName),
																!!errors.lastName && touched.lastName,
																errors.lastName
															)}
															<IMaskInput
																mask={pageUtils.MASKS.names}
																autoComplete="family-name"
																minLength={2}
																maxLength={50}
																className={joinClasses([
																	pageUtils.classes.formInput,
																	errors.lastName && touched.lastName
																		? pageUtils.classes.inputError
																		: ''
																])}
																placeholder=""
																{...field}
															/>
														</div>
													)}
												</Field>
												<Field name="phoneNumber">
													{({ field }) => (
														<div
															className={pageUtils.classes.responsiveContainer}
														>
															{formLabel(
																formatStrapiText(assets?.label_telephoneDay),
																!!errors.phoneNumber && touched.phoneNumber,
																errors.phoneNumber
															)}
															<IMaskInput
																mask={pageUtils.MASKS.phoneNumber}
																autoComplete="tel-national"
																minLength={10}
																maxLength={14}
																className={joinClasses([
																	pageUtils.classes.formInput,
																	errors.phoneNumber && touched.phoneNumber
																		? pageUtils.classes.inputError
																		: ''
																])}
																placeholder="(000) 000-0000"
																type="tel"
																{...field}
															/>
														</div>
													)}
												</Field>
												<Field name="phoneNumberEvening">
													{({ field }) => (
														<div
															className={pageUtils.classes.responsiveContainer}
														>
															{formLabel(
																formatStrapiText(
																	assets?.label_telephoneEvening
																),
																!!errors.phoneNumberEvening &&
																	touched.phoneNumberEvening,
																errors.phoneNumberEvening,
																false
															)}
															<IMaskInput
																mask={pageUtils.MASKS.phoneNumber}
																autoComplete="tel-national"
																minLength={10}
																maxLength={14}
																className={joinClasses([
																	pageUtils.classes.formInput,
																	errors.phoneNumberEvening &&
																	touched.phoneNumberEvening
																		? pageUtils.classes.inputError
																		: ''
																])}
																placeholder="(000) 000-0000"
																type="tel"
																{...field}
															/>
														</div>
													)}
												</Field>
											</div>
										</fieldset>
										<fieldset className={pageUtils.classes.fieldSet}>
											<h3 className={pageUtils.classes.addressTitle}>
												{formatStrapiText(assets?.label_address)}
											</h3>
											<div>
												<div className={pageUtils.classes.addressWrapper}>
													<Field name="address.inMunicipality">
														{() => (
															<>
																<div className={pageUtils.classes.radioWrapper}>
																	<input
																		type="radio"
																		id="inLaval"
																		value={`${LIVING_IN_LAVAL.YES}`}
																		className={pageUtils.classes.radioInput}
																		checked={
																			values.address.inMunicipality ===
																			LIVING_IN_LAVAL.YES
																		}
																		onChange={() =>
																			setFieldValue(
																				'address.inMunicipality',
																				LIVING_IN_LAVAL.YES
																			)
																		}
																	/>
																	<label
																		htmlFor="inLaval"
																		className={pageUtils.classes.radioLabel}
																	>
																		{formatStrapiText(
																			assets?.label_liveInLaval
																		)}
																	</label>
																</div>
																<div className={pageUtils.classes.radioWrapper}>
																	<input
																		type="radio"
																		id="notInLaval"
																		value={`${LIVING_IN_LAVAL.NO}`}
																		className={pageUtils.classes.radioInput}
																		checked={
																			values.address.inMunicipality ===
																			LIVING_IN_LAVAL.NO
																		}
																		onChange={() =>
																			setFieldValue(
																				'address.inMunicipality',
																				LIVING_IN_LAVAL.NO
																			)
																		}
																	/>
																	<label
																		htmlFor="notInLaval"
																		className={pageUtils.classes.radioLabel}
																	>
																		{formatStrapiText(
																			assets?.label_doNotLiveInLaval
																		)}
																	</label>
																</div>
															</>
														)}
													</Field>
												</div>
												{values.address.inMunicipality !==
													LIVING_IN_LAVAL.NO && (
													<div style={{ marginBottom: '-30px' }}>
														<label
															className={joinClasses([
																pageUtils.classes.formLabel,
																pageUtils.classes.required
															])}
															style={{ marginBottom: '10px' }}
														>
															{formatStrapiText(assets?.label_searchAddress)}
														</label>
														<div
															className={pageUtils.classes.locationButton}
															style={{ position: 'relative', top: '-15px' }}
														>
															<MapForm
																addressRequired
																classNames={{
																	address: joinClasses([
																		pageUtils.classes.formInput,
																		values.address.hasAddressEnteredManually
																			? pageUtils.classes.searchAddressDisabled
																			: '',
																		pageUtils.classes.map
																	])
																}}
																timer={100}
																onAddressChange={(address: AddressInfo) => {
																	setFieldValue('address.address', address.name)
																	setFieldValue(
																		'address.coordinates',
																		address.coordinates
																	)
																	setFieldValue(
																		'address.postalCode',
																		pageUtils.formatPostalCode(
																			address.postalCode
																		)
																	)
																}}
																isAddressInputDisabled={
																	values.address.hasAddressEnteredManually
																}
															/>
														</div>
														<p className={pageUtils.classes.helpText}>
															{!values.address.hasAddressEnteredManually && (
																<>
																	{formatStrapiText(
																		assets?.description_addressNotFound
																	)}{' '}
																</>
															)}
															<a
																href="#"
																onClick={(e) => {
																	e.preventDefault()
																	setFieldValue(
																		'address.hasAddressEnteredManually',
																		!values.address.hasAddressEnteredManually
																	)
																}}
																style={{ color: '#0054a6' }}
															>
																{formatStrapiText(
																	values.address.hasAddressEnteredManually
																		? assets?.description_cancelManualSearch
																		: assets?.description_manualSearch
																)}
															</a>
														</p>
													</div>
												)}
											</div>
											<div className={pageUtils.classes.inputBlock}>
												{(values.address.hasAddressEnteredManually ||
													values.address.inMunicipality ===
														LIVING_IN_LAVAL.NO) && (
													<Field name="address.address">
														{({ field }) => (
															<div
																className={
																	pageUtils.classes.responsiveContainer
																}
															>
																{formLabel(
																	formatStrapiText(assets?.label_address),
																	getIn(errors, 'address.address') &&
																		getIn(touched, 'address.address'),
																	getIn(errors, 'address.address'),
																	values.address.inMunicipality ===
																		LIVING_IN_LAVAL.NO ||
																		values.address.hasAddressEnteredManually
																)}
																<input
																	type="text"
																	placeholder=""
																	autoComplete="address"
																	className={joinClasses([
																		pageUtils.classes.formInput,
																		getIn(errors, 'address.address') &&
																		getIn(touched, 'address.address')
																			? pageUtils.classes.inputError
																			: ''
																	])}
																	{...field}
																/>
															</div>
														)}
													</Field>
												)}
												<Field name="address.apartment">
													{({ field }) => (
														<div
															className={pageUtils.classes.responsiveContainer}
														>
															<label className={pageUtils.classes.formLabel}>
																{formatStrapiText(assets?.apartment_label)}
															</label>
															<input
																type="text"
																placeholder=""
																autoComplete="address-level3"
																className={pageUtils.classes.formInput}
																{...field}
															/>
														</div>
													)}
												</Field>
											</div>
											{(values.address.hasAddressEnteredManually ||
												values.address.inMunicipality === LIVING_IN_LAVAL.NO ||
												values.address.hasAddressEnteredManually) && (
												<div className={pageUtils.classes.inputBlock}>
													<Field name="address.city">
														{({ field }) => (
															<div
																className={
																	pageUtils.classes.responsiveContainer
																}
															>
																{formLabel(
																	formatStrapiText(assets?.label_city),
																	getIn(errors, 'address.city') &&
																		getIn(touched, 'address.city'),
																	getIn(errors, 'address.city'),
																	values.address.inMunicipality ===
																		LIVING_IN_LAVAL.NO ||
																		values.address.hasAddressEnteredManually
																)}
																<input
																	type="text"
																	placeholder=""
																	autoComplete="address-level2"
																	className={joinClasses([
																		pageUtils.classes.formInput,
																		getIn(errors, 'address.city') &&
																		getIn(touched, 'address.city')
																			? pageUtils.classes.inputError
																			: ''
																	])}
																	{...field}
																/>
															</div>
														)}
													</Field>
													<Field name="address.state">
														{({ field }) => {
															return (
																<div
																	className={
																		pageUtils.classes.responsiveContainer
																	}
																>
																	{formLabel(
																		formatStrapiText(assets?.label_state),
																		getIn(errors, 'address.state') &&
																			getIn(touched, 'address.state'),
																		getIn(errors, 'address.state'),
																		values.address.inMunicipality ===
																			LIVING_IN_LAVAL.NO ||
																			values.address.hasAddressEnteredManually
																	)}
																	<input
																		type="text"
																		placeholder=""
																		autoComplete="address-level1"
																		className={joinClasses([
																			pageUtils.classes.formInput,
																			getIn(errors, 'address.state') &&
																			getIn(touched, 'address.state')
																				? pageUtils.classes.inputError
																				: ''
																		])}
																		{...field}
																	/>
																</div>
															)
														}}
													</Field>
													<Field name="address.postalCode">
														{({ field }) => (
															<div
																className={
																	pageUtils.classes.responsiveContainer
																}
															>
																{formLabel(
																	formatStrapiText(assets?.label_postalCode),
																	getIn(errors, 'address.postalCode') &&
																		getIn(touched, 'address.postalCode'),
																	getIn(errors, 'address.postalCode'),
																	values.address.inMunicipality ===
																		LIVING_IN_LAVAL.NO ||
																		values.address.hasAddressEnteredManually
																)}
																<IMaskInput
																	mask={pageUtils.MASKS.postalCode}
																	prepare={(str: string) => str.toUpperCase()}
																	className={joinClasses([
																		pageUtils.classes.formInput,
																		getIn(errors, 'address.postalCode') &&
																		getIn(touched, 'address.postalCode')
																			? pageUtils.classes.inputError
																			: ''
																	])}
																	autoComplete="postal-code"
																	placeholder="A1A 1A1"
																	{...field}
																/>
															</div>
														)}
													</Field>
													<Field name="address.country">
														{({ field }) => (
															<div
																className={
																	pageUtils.classes.responsiveContainer
																}
															>
																{formLabel(
																	formatStrapiText(assets?.label_country),
																	getIn(errors, 'address.country') &&
																		getIn(touched, 'address.country'),
																	getIn(errors, 'address.country'),
																	values.address.inMunicipality ===
																		LIVING_IN_LAVAL.NO ||
																		values.address.hasAddressEnteredManually
																)}
																<input
																	type="text"
																	placeholder=""
																	autoComplete="country-name"
																	className={joinClasses([
																		pageUtils.classes.formInput,
																		getIn(errors, 'address.country') &&
																		getIn(touched, 'address.country')
																			? pageUtils.classes.inputError
																			: ''
																	])}
																	{...field}
																/>
															</div>
														)}
													</Field>
												</div>
											)}
										</fieldset>

										<fieldset className={pageUtils.classes.fieldSet}>
											<h3 className={pageUtils.classes.addressTitle}>
												{formatStrapiText(
													assets?.label_communication_preferences
												)}
											</h3>
											<Field name="language" as="select">
												{({ field }) => (
													<div>
														<label className={pageUtils.classes.formLabel}>
															{formatStrapiText(
																assets?.label_communication_language
															)}
															<RequiredIcon />
														</label>
														<select
															className={joinClasses([
																pageUtils.classes.formSelect,
																pageUtils.classes.formInput
															])}
															required
															{...field}
															onChange={(e) => {
																setFieldValue('language', e.target.value)
																setTimeout(() => {
																	addTargetBlankToLink()
																}, 100)
															}}
														>
															<option value={LanguageEnum.FR}>
																{formatStrapiText(assets?.label_french)}
															</option>
															<option value={LanguageEnum.EN}>
																{formatStrapiText(assets?.label_english)}
															</option>
														</select>

														{values.language === LanguageEnum.EN && (
															<span
																className={joinClasses([
																	pageUtils.classes.textSpan,
																	'link-redirect'
																])}
															>
																<Text
																	content={
																		pageData.assets
																			?.label_notice_from_the_french_charter
																	}
																/>
															</span>
														)}
													</div>
												)}
											</Field>
										</fieldset>
										<fieldset
											className={joinClasses([
												pageUtils.classes.fieldSet,
												pageUtils.classes.mt15
											])}
										>
											<Field name="emailNotification">
												{() => (
													<div>
														<label className={pageUtils.classes.formLabel}>
															{formatStrapiText(
																assets?.label_emailNotification
															)}
														</label>
														<div className={pageUtils.classes.radioWrapper}>
															<input
																type="radio"
																id="emailNotificationYes"
																value={`${EMAIL_NOTIFICATION_OPTION.YES}`}
																className={pageUtils.classes.radioInput}
																checked={
																	values.emailNotification ===
																	EMAIL_NOTIFICATION_OPTION.YES
																}
																onChange={() =>
																	setFieldValue(
																		'emailNotification',
																		EMAIL_NOTIFICATION_OPTION.YES
																	)
																}
															/>
															<label
																htmlFor="emailNotificationYes"
																className={pageUtils.classes.radioLabel}
															>
																{formatStrapiText(assets?.label_yes)}
															</label>
														</div>
														<div className={pageUtils.classes.radioWrapper}>
															<input
																type="radio"
																id="emailNotificationNo"
																value={`${EMAIL_NOTIFICATION_OPTION.NO}`}
																className={pageUtils.classes.radioInput}
																checked={
																	values.emailNotification ===
																	EMAIL_NOTIFICATION_OPTION.NO
																}
																onChange={() =>
																	setFieldValue(
																		'emailNotification',
																		EMAIL_NOTIFICATION_OPTION.NO
																	)
																}
															/>
															<label
																htmlFor="emailNotificationNo"
																className={pageUtils.classes.radioLabel}
															>
																{formatStrapiText(assets?.label_no)}
															</label>
														</div>
													</div>
												)}
											</Field>
										</fieldset>
										<fieldset
											className={joinClasses([
												pageUtils.classes.fieldSet,
												pageUtils.classes.mt40
											])}
										>
											<div className={pageUtils.classes.buttonContainer}>
												{!isSubmitting ? (
													<ButtonLink
														text={formatStrapiText(assets?.button_complete)}
														type="button"
														onclick={() => {
															if (
																values?.address.apartment !== null ||
																values?.address.apartment !== ''
															) {
																setFieldValue(
																	'address.apartment',
																	DOMPurify.sanitize(values?.address.apartment)
																)
															}

															if (
																values.address.hasAddressEnteredManually ||
																values.address.inMunicipality ===
																	LIVING_IN_LAVAL.NO
															) {
																setFieldValue(
																	'address.address',
																	DOMPurify.sanitize(values?.address.address)
																)
																setFieldValue(
																	'address.city',
																	DOMPurify.sanitize(values?.address.city)
																)
																setFieldValue(
																	'address.state',
																	DOMPurify.sanitize(values?.address.state)
																)
																setFieldValue(
																	'address.country',
																	DOMPurify.sanitize(values?.address.country)
																)
															}
														}}
														wrapperClassName={pageUtils.classes.buttonWrapper}
													/>
												) : (
													<ButtonLink
														text="..."
														type="button"
														disabled
														wrapperClassName={pageUtils.classes.buttonWrapper}
													/>
												)}
											</div>
										</fieldset>
										<FormikErrorFocus
											formik={{} as any}
											offset={0}
											align={'top'}
											focusDelay={200}
											ease={'linear'}
											duration={10}
										/>
									</Form>
								)
							}}
						</Formik>
					</div>
				</>
			)}
		</AppStateContext.Consumer>
	)
}

export default MyAccountInformation
