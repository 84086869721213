import * as Yup from 'yup'
import Breakpoints from '@utils/breakpoints'
import { Colors, Constants } from '@utils/css-variables'
import { makeClasses, makeStyles } from '@utils/styles'
import { createMask } from 'imask'
import { LIVING_IN_LAVAL } from '@services/constants'

export type ClassType = {
	formInput: string
	inputWithoutSpinner: string
	responsiveContainer: string
	formSelect: string
	formLabel: string
	fieldSet: string
	textError: string
	inputError: string
	textDescription: string
	marginAuto: string
	radioWrapper: string
	mt40: string
	radioInput: string
	radioLabel: string
	addressTitle: string
	addressWrapper: string
	mainTitle: string
	container: string
	selectContainer: string
	inputBlock: string
	apartment: string
	buttonContainer: string
	buttonWrapper: string
	helpText: string
	loader: string
	searchAddressDisabled: string
	mt15: string
	required: string
	map: string
	locationButton: string
	textSpan: string
	notification: string
}

export const classes: ClassType = makeClasses({
	formInput: {
		fontFamily: Constants.fontStack,
		fontSize: '16px',
		width: 'calc(100% - 26px)',
		background: Colors.white,
		border: `1px solid ${Colors.grey}`,
		fontWeight: 400 as any,
		padding: '12px',
		borderRadius: '0px',
		lineHeight: '26px',
		marginBottom: '10px',
		'&:focus': {
			outline: 'none'
		}
	},
	helpText: {
		lineHeight: '1.62em',
		color: '#999',
		fontWeight: '400',
		fontSize: '14px',
		margin: 'unset',
		position: 'relative',
		top: '-35px'
	},
	inputWithoutSpinner: {
		'&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
			WebkitAppearance: 'none',
			margin: 0
		}
	},
	responsiveContainer: {
		[Breakpoints.minWidth('xs')]: {
			width: 'calc(50% - 8px)',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'end'
		},
		[Breakpoints.maxWidth('xs')]: {
			width: '100%'
		}
	},
	textError: {
		color: Colors.errorRed
	},
	textDescription: {
		margin: '0 0 8px',
		fontSize: '0.85em',
		lineHeight: '1.25em'
	},
	inputError: {
		border: `1px solid ${Colors.errorRed}`,
		'&:focus': {
			borderColor: Colors.errorRed,
			boxShadow: `0 0 0 2px ${Colors.errorRedTransparent20}`
		}
	},
	formSelect: {
		width: '100%',
		height: '52px'
	},
	formLabel: {
		margin: '5px 0 5px 0',
		paddingBottom: 0,
		fontSize: '16px',
		fontWeight: 'bold' as any,
		display: 'block'
	},
	fieldSet: {
		border: 'unset',
		marginBottom: '10px',
		clear: 'both' as any,
		display: 'flex',
		flexDirection: 'column' as any
	},
	marginAuto: {
		margin: 'auto'
	},
	radioWrapper: {
		display: 'flex',
		alignItems: 'baseline',
		margin: '0 0 5px 0',
		background: Colors.ligthGrey2,
		border: `1px solid ${Colors.greyAlto}`,
		padding: '6px 12px',
		position: 'relative'
	},
	mt40: {
		marginTop: '40px'
	},
	radioInput: {
		width: 'unset',
		transform: 'scale(1.5)',
		boxSizing: 'border-box',
		padding: '0',
		outline: 'none',
		margin: '5px',
		'&:before': {
			width: '13px',
			height: '13px',
			borderRadius: '100%',
			top: '-1px',
			left: '-1px',
			position: 'absolute',
			backgroundColor: Colors.lightGrey,
			content: "''",
			display: 'inline-block',
			visibility: 'visible',
			border: `1px solid ${Colors.grey}`
		},
		'&:checked': {
			'&:after': {
				content: "''",
				width: '7px',
				height: '7px',
				borderRadius: '100%',
				top: '3px',
				left: '3px',
				position: 'absolute',
				backgroundColor: Colors.secondary
			}
		}
	},
	radioLabel: {
		cursor: 'pointer',
		paddingLeft: '10px',
		fontWeight: 'normal',
		color: Colors.lightBlack
	},
	addressTitle: {
		marginTop: '20px',
		alignSelf: 'left',
		marginBottom: '20px',
		fontSize: '20px'
	},
	addressWrapper: {
		display: 'flex',
		flexDirection: 'column'
	},
	mainTitle: {
		lineHeight: '1.62em',
		textAlign: 'center',
		fontSize: '22px',
		fontWeight: '600' as any,
		margin: '20px 0'
	},
	container: {
		display: 'flex',
		flexDirection: 'column' as any,
		width: '100%',
		maxWidth: '700px',
		padding: '40px 70px',
		margin: 'auto',
		color: '#0a0a0a',
		[Breakpoints.maxWidth('lg')]: {
			padding: '50px 20px 20px 20px'
		}
	},
	selectContainer: {
		float: 'left',
		width: 'calc(50% - 8px)',
		[Breakpoints.maxWidth('lg')]: {
			marginRight: 0
		},
		[Breakpoints.maxWidth('xs')]: {
			width: '100%'
		}
	},
	inputBlock: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between'
	},
	apartment: null,
	buttonContainer: {
		display: 'flex'
	},
	buttonWrapper: {
		width: 'unset!important',
		lineHeight: '1em'
	},
	loader: {
		border: `4px solid ${Colors.lightGrey}`,
		borderTop: `4px solid ${Colors.blue}`,
		borderRadius: '50%',
		width: '26px',
		height: '26px',
		margin: '15px',
		animation: 'spin 2s linear infinite'
	},
	searchAddressDisabled: {
		background: Colors.lightGrey4
	},
	mt15: {
		marginTop: '15px'
	},
	required: {
		':after': {
			content: '"*"',
			marginLeft: '5px',
			color: Colors.ligthPurple
		}
	},
	map: {
		':invalid:focus': {
			border: `1px solid ${Colors.errorRed}`
		},
		':focus': {
			border: `1px solid ${Colors.primary}`
		}
	},
	locationButton: {
		'& input': {
			marginTop: '0px'
		},
		'& button': {
			maxHeight: '50px'
		}
	},
	textSpan: {
		color: Colors.darkGrey2,
		marginTop: '-5px',
		fontSize: '14px',
		textAlign: 'justify',
		p: {
			marginTop: 0
		}
	},
	notification: {
		margin: '0px 12px',
		div: {
			width: 'auto'
		}
	}
})

const PHONE_NUMBER_REGEX = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
const POSTAL_CODE_REGEX = /^\w\d\w[ -]?\d\w\d$/i

interface IMasks {
	postalCode: string
	phoneNumber: string
	names: RegExp
	mail: string
	email: RegExp
	creditCardNumber: string
	creditCardCSV: string
	creditCardExpiryDate: string
}

export const MASKS: IMasks = {
	postalCode: 'a0a 0a0',
	phoneNumber: '(000) 000-0000',
	names: /^[a-zA-ZÀ-ÿ-. ]*$/,
	mail: '000000',
	email: /^[A-Za-z0-9+_.-]+@+[A-Za-z0-9_-]+\.+[A-Za-z0-9]{2,3}$/,
	creditCardNumber: '0000 0000 0000 0000',
	creditCardCSV: '000',
	creditCardExpiryDate: '00/00'
}

const postalCodeMasker = createMask({
	mask: MASKS.postalCode
})

export const formatPostalCode = (postalCode?: string): string => {
	if (!postalCode) {
		return ''
	}

	return postalCodeMasker.resolve(postalCode)
}

export const SignupSchema = Yup.object().shape({
	firstName: Yup.string()
		.trim()
		.min(2, 'label_tooShortErrorText')
		.max(50, 'label_tooLongErrorText')
		.matches(/^[a-zA-ZÀ-ÿ-. ]*$/, 'label_firstNameErrorText')
		.required('required_text'),
	lastName: Yup.string()
		.trim()
		.min(2, 'label_tooShortErrorText')
		.max(50, 'label_tooLongErrorText')
		.matches(/^[a-zA-ZÀ-ÿ-. ]*$/, 'label_lastNameErrorText')
		.required('required_text'),
	phoneNumber: Yup.string()
		.min(10, 'label_tooShortErrorText')
		.max(14, 'label_tooLongErrorText')
		.matches(PHONE_NUMBER_REGEX, 'label_phoneNumberErrorText')
		.required('required_text'),
	phoneNumberEvening: Yup.string()
		.min(10, 'label_tooShortErrorText')
		.max(14, 'label_tooLongErrorText')
		.matches(PHONE_NUMBER_REGEX, 'label_phoneNumberErrorText'),
	address: Yup.object().shape({
		address: Yup.string().when(
			['inMunicipality', 'hasAddressEnteredManually'],
			{
				is: (inMunicipality: boolean, hasAddressEnteredManually: boolean) => {
					return (
						inMunicipality === LIVING_IN_LAVAL.NO || hasAddressEnteredManually
					)
				},
				then: Yup.string().required('required_text'),
				otherwise: Yup.string()
			}
		),
		city: Yup.string().when(['inMunicipality', 'hasAddressEnteredManually'], {
			is: (inMunicipality: boolean, hasAddressEnteredManually: boolean) => {
				return (
					inMunicipality === LIVING_IN_LAVAL.NO || hasAddressEnteredManually
				)
			},
			then: Yup.string().required('required_text'),
			otherwise: Yup.string()
		}),
		country: Yup.string().when(
			['inMunicipality', 'hasAddressEnteredManually'],
			{
				is: (inMunicipality: boolean, hasAddressEnteredManually: boolean) => {
					return (
						inMunicipality === LIVING_IN_LAVAL.NO || hasAddressEnteredManually
					)
				},
				then: Yup.string().required('required_text'),
				otherwise: Yup.string()
			}
		),
		state: Yup.string().when(['inMunicipality', 'hasAddressEnteredManually'], {
			is: (inMunicipality: boolean, hasAddressEnteredManually: boolean) => {
				return (
					inMunicipality === LIVING_IN_LAVAL.NO || hasAddressEnteredManually
				)
			},
			then: Yup.string().required('required_text'),
			otherwise: Yup.string()
		}),
		postalCode: Yup.string().when(
			['inMunicipality', 'hasAddressEnteredManually'],
			{
				is: (inMunicipality: boolean, hasAddressEnteredManually: boolean) => {
					return (
						inMunicipality === LIVING_IN_LAVAL.NO || hasAddressEnteredManually
					)
				},
				then: Yup.string()
					.min(6, 'label_tooShortErrorText')
					.max(7, 'label_tooLongErrorText')
					.required('required_text')
					.matches(POSTAL_CODE_REGEX, 'label_addressErrorText'),
				otherwise: Yup.string()
			}
		)
	})
})
